<template>
  <div class="wrap" ref="wrap">
		<div v-if="!result.state && !imgUrl">
			<div v-if="info.class == 1">
				<ul class="QA-top">
					<li>
						<div>识别编号:</div>
						<div><input v-model="QAnumber" type="number" placeholder="请输入识别编号" pattern="[0-9]*" @input="onNumber"></div>
					</li>
					<li>
						<div>评估日期:</div>
						<div><input v-model="QAdate" type="text" placeholder="请输入评估日期" maxlength="8" oninput="value=value.replace(/[^\d]/g,'')" readonly @click="dateShow = true"></div>
					</li>
				</ul>
						
				<!-- 评估日期 -->
				<van-calendar class="mank-calendar" v-model="dateShow" @confirm="onConfirm" />

				<div class="title">{{info.title}}</div>
				<div class="desc">{{info.desc}}</div>
				<div class="content">
					<img class="icon-left" src="../../assets/img/Q_frame_left.png" alt="">
					<img class="icon-right" src="../../assets/img/Q_frame_right.png" alt="">
					
					<!-- QA -->
					<ul class="question">
						<li v-for="(item, index) in lists" :key="index">
							<div class="topics">{{item.question}}</div>
							<ul class="option">
								<li v-for="(option, index1) in item.option_info" :key="index1" :class="option.on ? 'on' : ''" @click="optionClick(index, index1, item.type)">{{option.option}}</li>
							</ul>
						</li>
					</ul>
				</div>
			</div>

			<!-- 滑动 -->
			<div class="mk-slide" v-else-if="info.class == 2">
				<div class="title VAS-title">{{info.title}}</div>
				<div class="desc">{{info.desc}}</div>
				<div class="VAS" ref="VAS" @touchstart="touchStart($event)" @touchmove="touchMove('touch', $event)" @click="touchMove('click', $event)">
					<div class="my-slider">
						<div class="my-slider-spot">
							<div class="vas-scale-bg" ref="sliderBg"></div>
							<img class="vas-scale" ref="VASScale" src="../../assets/img/assess/vas-scale.png" alt="">
							<div class="my-slider-tip">
								<div>咳嗽极其严重</div>
								<div>无咳嗽</div>
							</div>
							<div class="my-slider-value" ref="slider">{{location.result}}</div>
						</div>
					</div>
				</div>
				<div class="copyright"><a href="https://beian.miit.gov.cn/#/home">沪ICP备13026779号-18&nbsp;&nbsp;&nbsp;&nbsp;</a><img src="../../assets/img/gongAn.png">&nbsp;<a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010402009903">沪公网安备31010402009903号</a></div>
			</div>

			<div class="assess" v-if="info.class == 1">
				<div @click="submit">计算</div>
				<div class="btn" @click="show = true">评分方法</div>
			</div>
		</div>
		<div v-else>
			<!-- <div class="result" ref="imageWrapper" v-show="!imgUrl">
				<img class="result-bg" src="../../assets/img/QA/qa-result.png" alt="">
				<div class="result-wrap">
					<div class="result-title">你的<br>{{info.title}}<br>计算结果:</div>
					<div class="result-num">总分值：<div><span>{{result.total}}</span><span>分</span></div></div>
					<div class="result-tip">{{info.explain}}</div>
					<div class="result-data">
						<div>识别编号:{{QAnumber}}</div>
						<div>评估日期:{{QAdate}}</div>
					</div>
				</div>
			</div> -->
		</div>

		<!-- 评分方法 -->
		<van-popup class="score-popup" v-model="show">
			<img class="score-img" :src="info.score_method" alt="">
		</van-popup>

		<!-- 答题结果海报 -->
		<div class="poster" v-if="result.state">
			<div class="title">{{info.title}}</div>
			<canvas v-show="!imgUrl" id="myCanvas" ref="canvas" width="675" height="897"></canvas>
			<div v-show="imgUrl">
				<div class="poster-img">
					<img :src="imgUrl" alt="">
				</div>
				<img class="tip-btn" src="../../assets/img/download.png" alt="">
				<div class="back" @click="afresh">重新计算 ></div>
			</div>
		</div>

		<!-- 参考文献 -->
		<Reference :info="refInfo"></Reference>
	</div>
</template>

<script>
import Vue from "vue"
import html2canvas from "html2canvas"
import Reference from '../../components/Reference'
import { Toast, RadioGroup, Radio, Popup, Calendar } from 'vant'
import { getToolQuestionList } from '../../common/api/index'
Vue.use(Radio).use(RadioGroup).use(Popup).use(Calendar)
export default {
  data() {
    return {
			id: '',
			QAnumber: '',
			QAdate: '',
			dateShow: false, // 日历
			lists: [],
			info: {},
			show: false, // 评分方法
			result: {
				state: false,
				total: 0
			},
			imgUrl: '',
			location: {
				client: {},
				position: {},
				result: 0
			},
			LCQTotal: '',
			LCQList: ['生理分值：', '心理分值：', '社会分值：'],
			CQLQTotal: '',
			CQLQList: ['躯体不适得分：', '社会心理问题得分：', '功能状态得分：', '情感健康得分：', '极端躯体不适得分：', '自身安全担忧得分：'],
			refInfo: '',
			resultImg: ''
		};
  },
	components: {
		Reference
	},
  created() {
		this.id = this.$route.query.id
		this.categoryId = this.$route.query.categoryId
		this.findQA()

		// 访问记录
		this.$Common._record({openid: this.$store.state.openid, module_id: 4, category_id: this.categoryId, questionnaire_id: this.id, page: this.$route.path})
	},
  mounted() {
		this.$wxShare._wxConfigJSSDK()
	},
  methods: {
		/**
		 * 获取问卷详情
		 */
		findQA () {
			Toast.loading({
        message: '',
        forbidClick: true,
        duration: 0
      })
			let that = this
			getToolQuestionList({questionnaire_id: that.id}).then(res => {
				Toast.clear()
				// console.log(res)
				if (res.code === 200) {
					let lists = res.data.question_list
					if (lists) {
						lists.forEach(function (value) {
							value.option_info.forEach(function (option) {
								option.on = false
							})
						})
						that.lists = lists
					}
					// questionnaire_info.class 1为QA类型，2为滑动类型
					that.info = res.data.questionnaire_info
					that.refInfo = res.data.questionnaire_info.reference
					if (res.data.questionnaire_info.class == 2) {
						that.$refs['wrap'].style.position = 'fixed'
					}
				} else {
					Toast(res.msg)
				}
			})
		},
		
		/**
		 * 日期格式
		 */
		formatDate(date) {
      return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
    },

		/**
		 * 选择日期
		 */
    onConfirm(date) {
      this.dateShow = false
      this.QAdate = this.formatDate(date)
    },

		/**
		 * 输入编号
		 */
		onNumber () {
			console.log(this.QAnumber)
			if (this.QAnumber.length > 4) {
				this.QAnumber = this.QAnumber.slice(0, 4)
			}
			if (this.QAnumber < 1 && this.QAnumber) {
				this.QAnumber = 1
			}
		},

		/**
		 * 选项选择
		 * QIndex => 题目下标
		 * OIndex => 选项下标
		 * type => 题目类型 1单选 2多选 3填空 4滑动 5嵌套 6下拉
		 */
		optionClick (QIndex, OIndex, type) {
			// console.log(this.lists[QIndex])
			let option = this.lists[QIndex].option_info
			if (type == 1) {
				option.forEach(function (value) {
          value.on = false
        })
			}
      this.lists[QIndex].option_info[OIndex].on = true
      this.lists[QIndex].on = true
		},

		/**
		 * 计算
		 */
		submit () {
			let total = 0
			let finish = true
			this.lists.forEach(function (value) {
				console.log(value)
				// 判断必填是否填写
				if (value.is_required == 1 && !value.on) {
					finish = false
				}

				// 分数统计（待调整）
				value.option_info.forEach(function (option) {
          if (option.on) {
						total += option.score
					}
        })
			})

			if (!finish) {
				Toast({
					message: '请完善问卷',
					className: 'submit-tip'
				})
				return false
			}

			if (this.id == '5') {
				total = this.LCQCountFun()
			} else if (this.id == '6') {
				total = this.CQLQCountFun()
			} else {
				total = this.countFun()
			}
			// console.log(total)

			this.result.state = true
			this.result.total = total

			// 提交问卷答题记录
			this.$Common._record({openid: this.$store.state.openid, module_id: 4, category_id: this.categoryId, questionnaire_id: this.id, page: this.$route.path})

			let that = this
			setTimeout(function () {
				that.drawImg()
			}, 200)
		},

		/**
		 * 计算总数
		 */
		countFun () {
			let that = this
			let total = 0
			that.lists.forEach(function (value) {
				value.option_info.forEach(function (option) {
					if (option.on) {
						total += option.score
					}
				})
			})
			return total
		},

		/**
		 * LCQ计算总数
		 */
		LCQCountFun () {
			let that = this
			let total = 0
			let dataArr = []
			dataArr = [
				[1, 2, 3, 9, 10, 11, 14, 15],
				[4, 5, 6, 12, 13, 16, 17],
				[7, 8, 18, 19]
			]
			let LCQTotal = []
			dataArr.forEach(function (value) {
				let valueTotal = 0
				// console.log(value.length)
				value.forEach(function (num) {
					that.lists[num - 1].option_info.forEach(function (option) {
						if (option.on) {
							valueTotal += option.score
						}
					})
				})

				let valueResult = (valueTotal / value.length).toFixed()
				LCQTotal.push(Number(valueResult))
			})
			// console.log(LCQTotal)
			this.LCQTotal = LCQTotal
			LCQTotal.forEach(function (num) {
				total += num
			})
			return total
		},

		/**
		 * CQLQ计算总数
		 */
		CQLQCountFun () {
			let that = this
			let total = 0
			let dataArr = []
			dataArr = [
				[4, 8, 10, 13, 14, 15, 17, 22, 23],
				[1, 18, 24, 25, 27],
				[2, 3, 19, 20, 21],
				[7, 9, 12, 16],
				[5, 6, 11, 12],
				[9, 26, 28]
			]
			let CQLQTotal = []
			dataArr.forEach(function (value) {
				let valueTotal = 0
				value.forEach(function (num) {
					that.lists[num - 1].option_info.forEach(function (option) {
						if (option.on) {
							valueTotal += option.score
						}
					})
				})

				CQLQTotal.push(valueTotal)
			})
			this.CQLQTotal = CQLQTotal
			CQLQTotal.forEach(function (num) {
				total += num
			})
			return total
		},

		/**
		 * 重新计算
		 */
		afresh () {
			this.result.state = false
			this.result.total = 0
			this.imgUrl = ''

			this.lists.forEach(function (value) {
				value.on = false
				value.option_info.forEach(function (option) {
					option.on = false
				})
			})
			
			this.$Common._record({openid: this.$store.state.openid, module_id: 4, category_id: this.categoryId, questionnaire_id: this.id, page: this.$route.path})
		},
		
    /**
     * 生成结果(旧版本)
     */
    createImg () {
			Toast.loading({
        message: '',
        forbidClick: true,
        duration: 0
      })
			let that = this
			html2canvas(that.$refs.imageWrapper, {
				backgroundColor: null
			}).then(canvas => {
				let dataURL = canvas.toDataURL("image/png")
				that.imgUrl = dataURL
				Toast.clear()
			}).catch(err => { /* eslint-disable-line */
				// console.log(err)
			})
    },

    /**
     * 生成结果（新版本）
     */
		drawImg () {
			Toast.loading({
        message: '',
        forbidClick: true,
        duration: 0
      })
			let that = this
			this.canvas = this.$refs.canvas
			this.ctx = this.canvas.getContext('2d')
			let img = new Image()
			img.setAttribute("crossOrigin",'Anonymous')
			if (that.id == '6') {
				img.src = 'https://qn-msd-max.rsproject.cn/qa-result1.png'
			} else {
				img.src = 'https://qn-msd-max.rsproject.cn/qa-result.png'
			}
			img.onload = function () {
				that.ctx.drawImage(img, 0, 0)

				that.drawText('你的', 39, 70, 600, 32, 1.5, '#ffffff', 'normal', that.ctx)
				that.drawText(that.info.title, 39, 125, 600, 32, 1.5, '#ffffff', 'normal', that.ctx)
				that.drawText('计算结果：', 39, 180, 600, 32, 1.5, '#ffffff', 'normal', that.ctx)
				that.drawText(that.info.explain, 39, 640, 580, 24, 1.5, '#ffffff', 'normal', that.ctx)
				that.drawText('识别编号：' + that.QAnumber, 400, 780, 600, 24, 1.5, '#ffffff', 'normal', that.ctx)
				that.drawText('评估日期：' + that.QAdate, 400, 820, 600, 24, 1.5, '#ffffff', 'normal', that.ctx)

				if (that.id == '6') {
					that.drawText('总分值：', 39, 563, 100, 32, 1.5, '#49FCFC', 'normal', that.ctx)
					that.drawText(that.result.total.toString(), 240, 470, 150, 90, 1.5, '#49FCFC', 'bold', that.ctx, 'center')
					that.drawText('分', 330, 570, 100, 32, 1.5, '#49FCFC', 'bolder', that.ctx)
				} else {
					that.drawText('总分值：', 39, 530, 100, 32, 1.5, '#49FCFC', 'normal', that.ctx)
					that.drawText(that.result.total.toString(), 240, 440, 150, 90, 1.5, '#49FCFC', 'bold', that.ctx, 'center')
					that.drawText('分', 330, 540, 100, 32, 1.5, '#49FCFC', 'bolder', that.ctx)
				}

				// LCQ
				if (that.id == '5') {
					for (let i = 0; i < that.LCQList.length; i++) {
						that.drawText(that.LCQList[i], 39, 280 + i * 60, 600, 32, 1.5, '#49FCFC', 'normal', that.ctx)
						that.drawText(that.LCQTotal[i] + '分', 200, 280 + i * 60, 600, 32, 1.5, '#49FCFC', 'bold', that.ctx)
					}
				}

				// CQLQ
				if (that.id == '6') {
					for (let i = 0; i < that.CQLQList.length; i++) {
						that.drawText(that.CQLQList[i], 39, 280 + i * 40, 600, 24, 1.5, '#49FCFC', 'normal', that.ctx)
						that.drawText(that.CQLQTotal[i] + '分', 330, 280 + i * 40, 100, 24, 1.5, '#49FCFC', 'bold', that.ctx, 'right')
					}
				}

				// 生成图片
				setTimeout(function () {
					that.imgUrl = that.canvas.toDataURL('image/png')
					Toast.clear()
				}, 200)
			}
		},

		/**
		 * text         文本
		 * x,y          轴
		 * width        行宽
		 * fontSize     字体大小
		 * rouHeight    行高，如果字体16px，1.5倍行距应该是24px
		 * ctx          画板内容对象
		*/
    drawText( text, x, y, width, fs, rowHeight, color, fw, ctx, align){
			// 1 将字符串转换成数组
			let test = text.split("")
			let temp = ""       
			let row = []

			ctx.font = `${fw} ${fs}px 微软雅黑`
			ctx.fillStyle = color
			ctx.textBaseline = "middle"
			if (align) {
				ctx.textAlign = align
			} else {
				ctx.textAlign = 'left'
			}
			// 计算文字宽度，若文字宽度大于设定的宽度，则push到数组下一个元素，否则将字符串++
			for(let i = 0;  i < test.length; i++ ){
				if( ctx.measureText(temp).width > width ){
					row.push(temp)
					temp = ""
				}
				temp += test[i]
			}
			// 1.3 循环结束将temp最后一段字符push
			row.push(temp)
			// 1.4 遍历数组,输出文字
			for(let j = 0; j < row.length; j++){
				ctx.fillText( row[j], x, y+(j+1) * fs *rowHeight)
			}
		},

		/**
		 * 禁止页面滚动
		 */
		initPageScroll() {
			document.body.addEventListener('touchmove', function (e) {
        e.preventDefault()
    	}, {passive: false})
		},

		/**
		 * VAS拖拽开始
		 */
		touchStart(e) {
			let element = e.targetTouches[0]
			this.location.client = {
				y: element.clientY
			}
			// 记录需要移动的元素坐标
			this.location.position.top = this.$refs['slider'].offsetTop
		},

		/**
		 * VAS拖拽结束
		 */
		touchMove(funName, e) {
			let clientY = funName == 'click' ? e.clientY : e.targetTouches[0].clientY

			let scaleTop = this.$refs['VASScale'].getBoundingClientRect().top
			let sliderH = this.$refs['slider'].offsetHeight / 2
			let scaleH = this.$refs['VASScale'].offsetHeight - sliderH
			let y = clientY - scaleTop - sliderH
			y = y <= - sliderH ? - sliderH : y >= scaleH ? scaleH : y
			this.$refs['slider'].style.top = y + 'px'

			// 数字
			let moveResult = this.location.result > 5 ? ((scaleH - y) / scaleH * 10  - 0.2).toFixed(1) : ((scaleH - y) / scaleH * 10  - 0.1).toFixed(1)
			moveResult = moveResult >= 10 ? 10 : (moveResult <=0 ? 0 : moveResult)
			this.location.result = moveResult

			// 背景进度
			this.$refs['sliderBg'].style.height = (moveResult * 10) + '%'
		}
	}
};
</script>

<style scoped lang="scss">
.wrap{
	width: 100%;
	height: 100%;
	// background: #000;
	padding-bottom: 50px;
}
.mank-calendar{
	color: #333;
}
.QA-top{
	width: 87%;
	margin: auto;
	margin-top: 102px;
	font-size: 24px;
	li{
		height: 50px;
		display: flex;
		align-items: center;

		&:last-child{
			margin-top: 20px;
		}

		div:last-child{
			height: 50px;
			flex: 1;
			position: relative;
			margin-left: 16px;

			&:after{
				content: '';
				width: 100%;
				height: 5px;
				position: absolute;
				bottom: 0;
				left: 0;
				background: url('../../assets/img/select_line.png') top center no-repeat;
				background-size: 100% 100%;
			}

			input{
				width: 100%;
				background: transparent;
				user-select: text;
  			-webkit-user-select: text;
			}
		}
	}
}
.title{
	width: 89%;
	height: 80px;
	line-height: 80px;
	font-size: 40px;
	text-align: center;
	margin: auto;
	margin-top: 20px;
	background: rgba(255, 255, 255, 0.1);
	border-radius: 18px;
	border: 3px solid #FFFFFF;
}
.VAS-title{
	margin-top: 92px;
}
.desc{
	width: 89%;
	font-size: 26px;
	margin: auto;
	margin-top: 34px;
}
.content{
	width: 90%;
	margin: auto;
	margin-top: 60px;
	box-sizing: border-box;
	padding: 50px;
	position: relative;
	font-size: 26px;
	font-weight: bold;

	&::before{
		content: '';
		width: 100%;
		height: 100%;
		border-radius: 18px;
		border: 3px solid #FFFFFF;
		position: absolute;
		top: 0;
		left: 0;
		background: #000;
		z-index: 1;
	}

	&::after{
		content: '';
		width: 100%;
		height: 100%;
		border-radius: 18px;
		border: 3px solid #FFFFFF;
		position: absolute;
		top: 10px;
		left: 10px;
	}

	.icon-left{
		width: 35px;
		position: absolute;
		top: -20px;
		left: 57px;
		z-index: 2;
	}

	.icon-right{
		width: 35px;
		position: absolute;
		top: -20px;
		right: 81px;
		z-index: 2;
	}
	.tip{
		position: relative;
		z-index: 2;
	}

	.question{
		position: relative;
		z-index: 2;
		>li{
			margin-top: 58px;

			&:first-child{
				margin-top: 0;
			}
		}

		.option{
			display: flex;
			flex-wrap: wrap;

			>li{
				min-width: 50px;
				display: flex;
				align-items: center;
				padding: 10px 10px 10px 0;
				margin-right: 20px;
				position: relative;
				&::before{
					content: '';
					width: 20px;
					height: 20px;
					border: 5px solid #FFF;
					border-radius: 50%;
					margin-right: 5px;
					box-sizing: border-box;
				}
			}

			>li.on{
				color: #49FCFC;
				&::before{
					border: 5px solid #49FCFC;
				}
				&::after{
					width: 100%;
					height: 5px;
					content: '';
					position: absolute;
					bottom: 0;
					left: 0;
					background: url('../../assets/img/home_line_1.png') top center no-repeat;
					background-size: 100% 100%;
				}
			}
		}
	}
}

.VAS{
	position: relative;
	z-index: 2;
	box-sizing: border-box;
	// margin-top: 30px;

	.my-slider{
		width: 137px;
		height: 940px;
		margin: auto;
		background: url(../../assets/img/assess/vas-bg.png) top center no-repeat;
		background-size: 100% 100%;
		position: relative;

		.my-slider-spot{
			width: 112px;
			position: absolute;
			top: 53px;
			left: -30px;
		}
		.vas-scale{
			width: 100%;
			position: relative;
			z-index: 3;
		}
		.vas-scale-bg{
			position: absolute;
			width: 137px;
			height: 0;
			bottom: -10px;
			left: 30px;
			background: #62F1F1;
			

			&:after{
				width: 99%;
				height: 70px;
				content: '';
				border-radius: 45%;
    		transform: scale(1,0.5);
				background: #62F1F1;
				position: absolute;
				bottom: -38px;
				left: 0.5%;
			}
			&:before{
				width: 99%;
				height: 70px;
				content: '';
				border-radius: 45%;
    		transform: scale(1,0.5);
				background: #49FCFC;
				border-bottom: 5px solid #FFDA00;
				position: absolute;
				top: -38px;
				left: 0.5%;
				z-index: 2;
			}
		}

		.my-slider-tip{
			word-break: keep-all;
			font-size: 17px;
			line-height: 26px;
			font-weight: bold;
			div:first-child{
				position: absolute;
				top: 0;
				right: -160%;
				color: #49FCFC;
			}
			div:last-child{
				position: absolute;
				bottom: 0;
				right: -120%;
			}
		}

		.my-slider-value{
			width: 92px;
			height: 56px;
			line-height: 56px;
			text-align: center;
			color: #49FCFC;
			font-size: 30px;
			font-weight: bold;
			position: absolute;
			background: #000;
			top: calc(100% - 28px);
			left: -100%;
			border: 4px solid #49FCFC;
			border-top-left-radius: 8px;
			border-top-right-radius: 8px;
			border-bottom-left-radius: 8px;
			box-sizing: border-box;

			&::after{
				content: '';
				width: 0;
				height: 0;
				border-top: 10px solid transparent;
				border-left: 15px solid #49FCFC;
				border-bottom: 10px solid transparent;
				position: absolute;
				left: 101%;
				top: 50%;
				transform: translateY(-50%);
			}

			&::before{
				content: '';
				width: 0;
				height: 0;
				border-top: 15px solid transparent;
				border-left: 20px solid #FFDA00;
				border-bottom: 15px solid transparent;
				position: absolute;
				left: 102%;
				top: 50%;
				transform: translateY(-50%);
			}
		}
	}
}
.assess{
	width: 89%;
	height: 97px;
	line-height: 83px;
	font-size: 40px;
	color: #fff;
	text-align: center;
	background: url('../../assets/img/btn_bg.png') top center no-repeat;
	background-size: 100% 100%;
	margin: auto;
	margin-top: 35px;
	position: relative;

	.btn{
		position: absolute;
		top: 30px;
		right: 44px;
		font-size: 24px;
		line-height: 26px;
		color: #FFDA00;
		text-decoration: underline;
	}
}

.result{
	width: 90%;
	position: relative;
	margin: auto;
	// margin-top: 229px;
	.result-bg{
		width: 100%;
	}

	.result-wrap{
		width: 90%;
		height: 92%;
		position: absolute;
		left: 5%;
		top: 5%;

		.result-num{
			color: #49FCFC;
			font-size: 32px;
			line-height: 32px;
			font-weight: 800;
			display: flex;
			align-items: center;
			margin: 150px 0 70px 0;

			div{
				display: flex;
				align-items: flex-end;
				margin-left: 20px;

				span:first-child{
					font-size: 120px;
					line-height: 120px;
				}
			}
		}

		.result-title{
			font-size: 32px;
			margin-top: 100px;
			line-height: 56px;
		}

		.result-tip{
			font-size: 24px;
		}

		.result-data{
			font-size: 20px;
			position: absolute;
			bottom: 0;
			right: 0;
		}
	}
}
.tip-btn{
	width: 88%;
	margin: auto;
	margin-top: 47px;
}
.back{
	font-size: 32px;
	color: #FFDA00;
	text-align: center;
	margin-top: 20px;
}

.score-popup{
	width: 100%;

	.score-img{
		width: 100%;
	}
}

#myCanvas{
	width: 675px;
	position: fixed;
	top: 15%;
	left: 37.5px;
	// z-index: 999;
	// opacity: 0;
}

// 海报
.poster{
	width: 90%;
	height: 90%;
	// position: absolute;
	// top: 6%;
	// left: 5%;
	margin: auto;
	margin-top: 80px;
	padding-bottom: 30px;
	overflow-y: scroll;

	.title{
		width: auto;
	}
	
	.poster-img{
		margin-top: 30px;
		min-height: 600px;
	}

	img{
		width: 100%;
		pointer-events: initial;
	}

	.tip-btn{
		pointer-events: none;
	}
}

.mk-slide{
	width: 100%;
	height: 100%;
	background: #000;
	position: fixed;
	top: 0;
	.desc{
		margin-top: 10px;
	}
	.copyright{
		bottom: 10px;
	}
}

@media (device-height:568px) and (-webkit-min-device-pixel-ratio:2){/* 兼容iphone5 */
	.my-slider{
		height: 780px!important;
	}
	.my-slider-spot{
		height: calc(100% - 53px);
		left: -25px!important;
	}
	.vas-scale{
		width: auto!important;
		height: 100%;
	}
	.vas-scale-bg{
		left: 25px!important;
		bottom: 10px!important;
	}
}
@media (device-height:667px) and (-webkit-min-device-pixel-ratio:2){/*兼容iphone6，iphone7，iphone8s ：*/
	.my-slider{
		height: 780px!important;
	}
	.my-slider-spot{
		height: calc(100% - 53px);
		left: -25px!important;
	}
	.vas-scale{
		width: auto!important;
		height: 100%;
	}
	.vas-scale-bg{
		left: 25px!important;
		bottom: 10px!important;
	}
}
@media (device-height:736px) and (-webkit-min-device-pixel-ratio:2){/*兼容iphone6 Plus,iphone7 Plus,iphone8 Plus：*/
	.my-slider{
		height: 780px!important;
	}
	.my-slider-spot{
		height: calc(100% - 53px);
		left: -25px!important;
	}
	.vas-scale{
		width: auto!important;
		height: 100%;
	}
	.vas-scale-bg{
		left: 25px!important;
		bottom: 10px!important;
	}
}
</style>
